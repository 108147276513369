import { useStaticQuery } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import ArticleCard from '../components/articleCard'
import blogStyles from './blog.module.scss';

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { order: ASC, fields: publishedDate }) {
        edges {
          node {
            blogImage {
              file {
                url
              }
              description
            }
            category
            slug
            title
            publishedDate(formatString: "MMMM Do, YYYY")
            seoDescription
          }
        }
      }
    }
  `)

  return (
    <Layout title="Latest Articles" btnText="" btnLink="">
      <Head title="Latest Articles" />
      <section>
      <wrapper className={blogStyles.articles__container} >
        {data.allContentfulBlogPost.edges.map(edge => {
          return (
            <ArticleCard
                  image={edge.node.blogImage.file.url}
                  alt={edge.node.blogImage.description}
                  slug={`/blog/${edge.node.slug}`}
                  category={edge.node.category}
                  title={edge.node.title}
                  description={edge.node.seoDescription}
                />
          )
        })}
      </wrapper>
      </section>
    </Layout>
  )
}

export default BlogPage
